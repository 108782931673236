import React from "react";

function Body() {
  return (
    <div className="body">
      <div className="aboutMe">
        <h1>About Me</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam
          doloremque praesentium provident consectetur quis amet reprehenderit
          repudiandae suscipit, ducimus dolorem quasi quas voluptates
          dignissimos quae hic laborum delectus nesciunt perferendis.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quibusdam
          doloremque praesentium provident consectetur quis amet reprehenderit
          repudiandae suscipit, ducimus dolorem quasi quas voluptates
          dignissimos quae hic laborum delectus nesciunt perferendis.
        </p>
      </div>
    </div>
  );
}

export default Body;
